<template>
  <div class="page-people-list-simple">
    <h3 class="page-people-list-simple__header">Действующие игроки</h3>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div v-if="false" class="reminder smalltext">работает сортировка по столбцам</div>
      <table class="t_table">
        <tr class="noselect">
          <template v-for="(c, i) in columnList">
            <th
              :ref="c + '_sort'"
              :key="i"
              @click="sort(c, people, $refs, oFlag)"
              class="pointer"
            >
              {{ columnListCaption[i] }}
            </th>
          </template>
        </tr>
        <tr v-for="(row, y) in people" :key="y">
          <template v-for="(c, i) in columnList">
            <td :key="i" :ref="y === 0 ? 'cell' + c : null" class="txt">
              <span @click="enlarge(c, row[c])" v-html="decorateField(c, row[c])"></span>
            </td>
          </template>
        </tr>
        <tr v-if="people.length === 0">
          <td colspan="300" class="center">нет данных по выбранным параметрам</td>
        </tr>
      </table>
    </div>
    <div v-if="enlargeFlag" class="modalTurn" @click="close()">
      <div style="display: flex">
        <img :src="imagePhoto" alt="фоточка" style="object-fit: contain; height: 100vh" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { request } from "@/components-js/requestSrv";
import { decorateField } from "@/components-js/decorateField.js";
import { sortCol } from "@/components-js/sortCol.js";
import { PHOTO_ROUTE } from "@/config/settings";

export default {
  components: { Loader },
  data() {
    return {
      loading: false,
      people: [],
      oFlag: 1,
      person: null,
      showFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],
      // selection list show/hide
      columnSetup: false,
      enlargeFlag: 0,
      imagePhoto: "",
    };
  },

  async mounted() {
    this.tableDataRetrieve();
  },

  methods: {
    async tableDataRetrieve() {
      this.loading = true;
      this.people = await request("/api/report/peoplelistsimple", "POST", {
        uid: this.$root?.user?.uid,
      });

      this.people.forEach(el => {
        if (el.job_extra) el.job_name = el.job_extra;
      });

      this.columnList = [];
      this.columnList.push("photo");
      this.columnListCaption.push("Фото");
      this.columnList.push("nickname");
      this.columnListCaption.push("Ник");
      this.columnList.push("job_name");
      this.columnListCaption.push("Должность");
      this.columnList.push("telegram");
      this.columnListCaption.push("Telegram");

      this.loading = false;
    },

    enlarge(field, value) {
      if (field !== "photo") return;
      this.imagePhoto = `${PHOTO_ROUTE}${value}`;
      this.enlargeFlag = value;
    },

    close() {
      this.enlargeFlag = 0;
    },

    decorateField(field, value) {
      return decorateField(field, value)
        .toString()
        .replace("/>", ' style="width: 40px; object-fit: cover;" />');
    },

    // rolesOut(rolesArr) {
    //   let res = "";
    //   for (let i in rolesArr) {
    //     res += rolesArr[i];
    //     res += i < rolesArr.length - 1 ? ", " : "";
    //   }
    //   return res;
    // },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-people-list-simple {
  @media (max-width: 1300px) {
    &__header {
      padding: 16px;
      font-size: 20px;
      font-weight: 800;
      line-height: 28px;
    }
  }
}
.t_table td {
  border-left: none;
  border-right: none;
}
</style>
