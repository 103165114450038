<template>
  <div>
    <component v-bind:is="people"></component>
  </div>
</template>

<script>
import PeopleListSimple from "@/views/PeopleListSimple.vue";
import PeopleList from "@/views/PeopleList.vue";

export default {
  components: {
    PeopleListSimple,
    PeopleList,
  },
  data() {
    return { people: String };
  },
  mounted() {
    if (this.$root?.user?.roles.includes("admin") || this.$root?.user?.roles.includes("hradmin"))
      this.people = "PeopleList";
    else this.people = "PeopleListSimple";
  },
};
</script>
